module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content-page">\r\n    <ul>\r\n        <li class="page-prev">\r\n            <a href="javascript:;">\r\n                <img src="' +
((__t = ( require('./images/left.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                <img src="' +
((__t = ( require('./images/left1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n            </a>\r\n        </li>\r\n        <li class="active"><a href="javascript:;">1</a></li>\r\n        <li><a href="javascript:;">2</a></li>\r\n        <li><a href="javascript:;">3</a></li>\r\n        <li><a href="javascript:;">4</a></li>\r\n        <li><a href="javascript:;">5</a></li>\r\n        <li><a href="javascript:;">6</a></li>\r\n        <li class="page-next">\r\n            <a href="javascript:;">\r\n                <img src="' +
((__t = ( require('./images/right.png') )) == null ? '' : __t) +
'" alt="" class="img1">\r\n                <img src="' +
((__t = ( require('./images/right1.png') )) == null ? '' : __t) +
'" alt="" class="img2">\r\n            </a>\r\n        </li>\r\n    </ul>\r\n</div>';

}
return __p
}