import './index.scss'

if ( window.innerWidth>768) {
    $(window).scroll(function () {
        // 可是窗口滚动条发生滚动的时候 垂直方向滚动到多少距离的时候 让头部导航条设置上一个固定定位的样式
            if ($(window).scrollTop()>=($("header").height())) {
                $("header").addClass("head_scroll");
                $(".head").addClass("head1")
            } else {
                $("header").removeClass("head_scroll");
                $(".head").removeClass("head1")
            }
    })
}else {
    $("header").addClass("head_scroll");
    $(".head").addClass("head1")
}
$(".nav li").hover(function(){
    $(this).find(".li-drop").stop().slideDown();
},function(){
    $(this).find(".li-drop").stop().slideUp();
})
$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
})
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})